.indicator {
  display: flex;
  align-items: center;
  min-width: 70px;
  font-size: 12px;
  justify-content: center;
}

.inlineIndicator {
  display: inline-block;
  min-width: 70px;
  font-size: 11px;
  line-height: normal;
  text-align: center;
  border-radius: 4px;
  padding: 4px 8px;
}

.withLogo {
  display: flex;
  align-items: center;
  gap: var(--space-1);
  padding: 0;
  min-width: 115px;
  font-size: 14px;
  justify-content: flex-start;
}

@media (max-width: 899.95px) {
  .indicator {
    min-width: 35px;
  }
  .responsive {
    min-width: 0;
  }
  .responsive .name {
    display: none;
  }
}

@container my-accounts-container (max-width: 500px) {
  .responsive {
    min-width: 0;
  }
  .responsive .name {
    display: none;
  }
}
